.excel-upload-preview-container {
  display: flex;
  flex-direction: column;
}

.excel-upload-dropzone {
  margin-bottom: 20px;
}

.excel-preview-container {
  margin-left: 2rem;
  margin-top: 1rem;
  flex-direction: row;
  display: flex;
}

.excel-tags {
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  width: 11.1rem
}

.excel-tag {
  cursor: pointer;
  margin-bottom: 5px;
  width: 10rem;
  /* height: 1.95rem;
  font-size: 0.86rem; */

  
  height: 1.7rem;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 5px;
  border: 1px solid #0078D4;
  background-color: #0078D4;
  /* Green */
  color: white;

  text-align: center;
}

.excel-tag.selected {
  font-weight: bold;
  border: 1px solid #86bc25;
  background-color: #86bc25;
}

.excel-sheet-preview {
  flex: 1;
}

.s3-table-container {
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow-x: auto;
  height: 17rem !important;
  width: 93rem !important;
  background-color: white;
  font-size: 0.65rem;
  margin-left: 1rem;
  margin-top: 0.8rem;
}

.s3-table-container table {
  width: 100%;
  border-collapse: collapse;
}

.s3-table-container th,
.s3-table-container td {
  border: 1px solid #ddd;
  padding: 8px;
  height: 2rem !important;
}

.s3-table-container th {
  /* background-color: #f2f2f2 f4c69e E4ECF7; */
  background-color: #d5e3f7;
  text-align: center;
  border: 1px solid #9EB6CE;
  font-size: 0.71rem !important;
}

.s3-table-container th:first-child,
.s3-table-container td:first-child {
  border-left: none;
}

.s3-table-container th:last-child,
.s3-table-container td:last-child {
  border-right: none;
}


.out-table-container {
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow-x: auto;
  height: 12.15rem !important;
  width: 80rem !important;
  background-color: white;
  font-size: 0.7rem;
  margin-left: -1rem;
}

.out-table-container table {
  width: 100%;
  border-collapse: collapse;
}

.out-table-container th,
.out-table-container td {
  border: 1px solid #ddd;
  padding: 8px;
  height: 2rem !important;
}

.out-table-container th {
  /* background-color: #f2f2f2; */
  background-color: #d5e3f7;
  text-align: center;
  border: 1px solid #9EB6CE;
  font-size: 0.71rem !important;
}

.out-table-container th:first-child,
.out-table-container td:first-child {
  border-left: none;
}

.out-table-container th:last-child,
.out-table-container td:last-child {
  border-right: none;
}



.excel-tabs {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-left: 2rem;
}

.excel-tab {
  border: 0px solid #ccc;
  border-radius: 0px;
  margin-right: 10px;
  padding: 5px;
  width: 15rem;
  text-align: center;
  cursor: pointer;
  background-color: #0078D4;
  color: white;
  font-weight: bold;
  border-color: #0078D4;
  height: 2.25rem;
  font-size: 0.9rem;
}

.excel-tab.active {
  background-color: #86bc25;
  color: white;
  font-weight: bold;
  border-color: #86bc25;
}

/* 
.excel-tag {
  margin-right: 5px;
  padding: 5px;
  cursor: pointer;
}

.excel-tag.selected {
  background-color: #007bff;
  color: #ffffff;
}

.excel-sheet-preview {
  margin-top: 10px;
} */


.drop-zone-area {
  height: 50% !important;
}

.css-1zvbg3-MuiStepper-root .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiStepIcon-root {
  color: #86bc26 !important
}

.MuiDropzoneArea-root {
  width: 60% !important;
  border: dashed;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  height: 2.2rem !important;
  margin-left: 2rem !important;
  min-height: 1rem !important;
  border-color: rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  background-color: white !important;
  margin-top: -0.5rem !important;
}

.MuiDropzoneArea-text {
  margin-top: 10px !important;
  /* margin-left: -11rem !important; */
}

.css-1atslg0-MuiTypography-root {
  height: 32rem !important
}

.MuiTypography-h5 {
  font-size: 0.85rem !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 100 !important;
  line-height: 1 !important;
  letter-spacing: 0em;
  margin-left: 2rem !important;
  margin-top: 0.6rem !important;
}

.MuiDropzoneArea-icon {
  color: rgba(0, 0, 0, 0.87);
  width: 51px;
  height: 32px !important;
  margin-top: -6rem !important;
  margin-left: -11rem !important;
}

.ExcelTable2007 {
  border: 1px solid #E4ECF7;
  border-width: 1px 0px 0px 1px;
  font-size: 11pt;
  font-family: Calibri;
  font-weight: 100;
  border-spacing: 0px;
  border-collapse: collapse;
  padding: 10px;
  background-color: #e4ecf7;
}

.ExcelTable2007 TH {
  background-repeat: repeat-x;
  font-weight: bold;
  font-size: 14px;
  border: 1px solid #9EB6CE;
  border-width: 0px 1px 1px 0px;
  height: 17px;
  line-height: 17px;
}

.ExcelTable2007 TD {
  border: 0px;
  background-color: white;
  padding: 0px 4px 0px 2px;
  border: 1px solid #D0D7E5;
  border-width: 0px 1px 1px 0px;
}

.ExcelTable2007 TD B {
  border: 0px;
  background-color: white;
  font-weight: bold;
}

.ExcelTable2007 TD.heading {
  background-color: #E4ECF7;
  text-align: center;
  border: 1px solid #9EB6CE;
  border-width: 0px 1px 1px 0px;
  font-weight: bold;
}

.restrict-card {
  height: 35.6rem;
  overflow-y: overlay;
}

.ExcelTable2007 TD B {
  border: 0px;
  background-color: white;
  font-weight: bold;
}

.ExcelTable2007 TD.heading {
  background-color: #E4ECF7;
  text-align: center;
  border: 1px solid #9EB6CE;
  border-width: 0px 1px 1px 0px;
  font-weight: bold;
}

.ExcelTable2007 TH.heading {
  background-repeat: none;
}

TH {
  text-align: center;
}

.card {
  width: 50rem;
  height: 3rem;
  /* Set your desired height value */
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
  /* Add space between cards */
}

.card h5,
.card p {
  margin: 0;
  /* Remove default margin for title and content */
}

.card-container {
  display: flex;
  flex-direction: column;
}


/* App.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.modal button {
  margin: 5px;
}



.modal {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  height: 150px;
  /* Increase the height as needed */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.button-container {
  display: flex;
  justify-content: center;
  /* Center-align the buttons */
}

.confirm-button,
.cancel-button {
  padding: 10px 20px;
  margin: 0 10px;
  /* Add margin between the buttons */
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.confirm-button {
  background-color: #0078D4;
  color: #fff;
}

.cancel-button {
  background-color: #dc3545;
  color: #fff;
}