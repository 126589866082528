/* Add these styles to your global CSS */
/* .custom-hover:hover {
    background-color: #86bc26 !important;
    color: #ffffff !important;
  } */
.custom-hover:hover h2 {
  color: #97d42a !important;
  font-size: 1.39rem !important;
  /* margin-left: 0.3rem !important; */
  text-align: center !important;
  align-items: center !important;
  /* Change text color on hover */
}