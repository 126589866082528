.main-page {
    display: flex;
    justify-content: center;
  }
  
  .container {
    display: flex;
  }
  
  .menu-container {
    margin-right: 0px; /* Adjust as needed */
    margin-top: 4rem;
  }
  .menu-item.active {
    background-color: #86bc26 !important; /* Change the background color for the active item */
    color: white; /* Change the text color for the active item */
  }